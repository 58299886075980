var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('app-header'),(_vm.currentComplexWaterSample)?_c('div',{staticClass:"waterSamplesDetail"},[_c('water-bottle',{key:_vm.currentComplexWaterSample.WsID?_vm.currentComplexWaterSample.WsID:'Surface',staticClass:"inDetails",attrs:{"template":{data:_vm.currentComplexWaterSample},"options":{
        PrID: _vm.PrID,
        data: _vm.ComplexWaterSamples,
        MpGuid: _vm.MpGuid,
        FtGuid: _vm.currentComplexWaterSample.guid,
        FtID: _vm.currentComplexWaterSample.id,
        showAddButton: true,
        enableBoxLink: true,
        type: _vm.type
      }}}),(_vm.workingObject && _vm.workingObjectType === 'waterSample')?_c('editors',{staticClass:"inDetails",attrs:{"screen":"frmWatersampleDetails"}}):_vm._e(),(_vm.workingObject && _vm.workingObjectType === 'bottle')?_c('editors',{staticClass:"inDetails",attrs:{"screen":"frmBottleDetails"}}):_vm._e(),_c('nv-loading',{attrs:{"show":_vm.loading}}),_c('ExportProjectPopup',{attrs:{"exportPopupOpen":_vm.exportPopupOpen},on:{"closeButtonClicked":_vm.toggleExportPopup}})],1):_vm._e(),_c('app-footer',{attrs:{"saveComponentDataFunction":_vm.save}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }