<template>
  <div>
    <!-- Modal add airSample-->
    <div :id="'modalAddAirSample' + option.guid"
         class="modal fade"
         tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="close"
                    data-dismiss="modal">&times;</button>
            <h4 :id="'modalAddWaterSampleTitle' + option.guid"
                class="modal-title">{{$t("project.AddAirSampleTitle")}}</h4>
          </div>
          <div class="modal-body">
            <div v-if="option.type === 'WATER'" class="gauging-tube">
              {{$t("label.gaugingTubes")}}: {{option.name}} ({{option.from}}-{{option.to}})
            </div>
            <p>{{$t("project.AirSampleName")}}</p>
            <input :id="'txtAirSampleName' + option.guid" v-model="name" type="text" style="  display: inline;" class="form-control" @keypress="checkSubmit">
            <p v-if="!valid" class="error">{{errorMessage}}</p>
            <p>{{$t("project.WaterSampleMatrix")}}</p>
            <select v-model='matrixcode'
                    class="form-control">
              <option v-for="(mcode, key) in filterGroundAndGarbageAirMatrixList" :key="key"
                      :value="mcode.value">{{mcode.text}} - {{mcode.desc}}</option>
            </select>
          </div>
          <div class="modal-footer">
            <button :id="'btnAddWaterSample' + option.guid"
                    type="button"
                    class="btn"
                    v-bind:class="{ disabled: !enableAddButton }"
                    @click="add">{{$t("project.CreateButton")}}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal WaterSample-->
    <div :id="'modalDeleteAirSample' + option.guid"
         class="modal fade"
         tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button"
                    class="close"
                    data-dismiss="modal">&times;</button>
            <h4 id="modalDeleteWaterSampleTitle"
                class="modal-title">{{$t("project.DeleteAirSampleTitle")}}</h4>
          </div>
          <div class="modal-body">
            <p>{{$t("project.DeleteAirSampleHelp")}}</p>
          </div>
          <div class="modal-footer">
            <button :id="'btnDeleteWaterSample' + option.guid"
                    class="btn"
                    @click="deleteWaterSample">{{$t("project.DeleteButton")}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
'use strict'
import Vue from 'vue'
import actions from '@/vuex/actions'
import getters from '@/vuex/getters'
import fieldCodeUtils from '@/utils/fieldCodeUtils'
import { TemplateService } from '@/services/DataWS/templateService'

const templateService = new TemplateService()
export default {
  name: 'asModal',
  props: ['functions', 'option'],

  data () {
    return {
      name: '',
      matrixcode: 'BL',
      errorMessage: '',
      valid: false,
      validationError_to: '',
      lang: Vue.config.lang,
      relationLang: {
        nl: 'nld',
        en: 'eng',
        fr: 'fra',
        es: 'spa',
        de: 'deu',
        it: 'ita'
      }
    }
  },

  computed: {
    matrixcodeList () {
      return this.$store.getters.getTemplateField('matrixcodeList')
    },
    enableAddButton () {
      return this.valid && !this.$parent.loading
    },
    filterGroundAndGarbageAirMatrixList () {
      return this.matrixcodeList.filter((mcode) => mcode.value === 'OL' || mcode.value === 'BL')
    }
  },

  vuex: {
    actions: {
      setTemplateField: actions.setTemplateField
    },
    getters: {
      getTemplateField: getters.getTemplateField
    }
  },

  watch: {
    'option.suggestedName': {
      handler: function () {
        this.prepareName()
      }
    },
    'name': {
      handler: function () {
        this.validate()
      }
    }
  },

  mounted () {
    let options = this.$store.getters.getTemplateField('WsMatrixCode')
    if (!options) {
      // lock
      this.$store.dispatch('setTemplateField', { key: 'WsMatrixCode', value: 'tempData' })

      let projectID = this.$route.params.projectId
      templateService.getWsMatrixCode(projectID).then(v => {
        let field = v.controls[2].fields.find(f => { return f.key === 'WsMatrixCode' })
        this.$store.dispatch('setTemplateField', { key: 'WsMatrixCode', value: field })
        this.handleList(field)
      }).catch(err => {
        console.log(err)
      })
    }
  },

  methods: {
    prepareName () {
      if (this.option.suggestedName.startsWith('AS-')) {
        this.name = this.option.suggestedName
      } else {
        this.name = 'AS-' + this.option.suggestedName
      }
    },
    handleList (field) {
      let matrixcodeList = field.options.map(v => { return fieldCodeUtils.transformItem(this, v) })
      this.$store.dispatch('setTemplateField', { key: 'matrixcodeList', value: matrixcodeList })
    },
    checkSubmit (evt) {
      if (evt.key === 'Enter' && this.valid) {
        this.add()
      }
    },
    validate () {
      let WsNameField = {
        key: 'WsName',
        maxLength: 50,
        requiredBySystem: true
      }
      let projectID = this.$route.params.projectId
      this.$validateEditor(WsNameField, this.name, {
        PrID: projectID, WsGuid: '' /* empty since it cant ever match with itself */
      })
        .then((res) => {
          this.valid = true
        }).catch(reason => {
        // extract reason and set valid to false
          if (typeof reason.message === 'undefined') { console.error(reason) }
          this.valid = false
          this.errorMessage = reason.message
        })
    },
    add () {
      return this.functions.add(this.option.key, { name: this.name, matrixcode: this.matrixcode, type: 'AIR' })
    },
    resetDefault () {
      this.matrixcode = ''
      this.errorMessage = ''
      this.validationError_to = ''
      this.name = this.option.suggestedName
    },
    deleteWaterSample () {
      return this.functions.delete(this.option.key)
    }
  }
}
</script>

<style lang="less">
  .modal-body {
    .gauging-tube {
      background: #07828a;
      color: #fff;
      padding: 7px;
      margin-bottom: 5px;
      width: 250px
    }
  }
</style>
