<template>
  <div>
  <div class="water-bottle-box">
    <div class="title-box">
      <div class="title">
        <h5 v-if="options.type === 'WATER'" >{{$t("label.WaterSamples")}}</h5>
        <h5 v-if="options.type === 'SURFACEWATER'" >{{$t("label.SurfaceWaterSamples")}}</h5>
        <h5 v-if="options.type === 'AIR'" >{{$t("label.AirSamples")}}</h5>
        <h5 v-if="options.type === 'SURFACEAIR'" >{{$t("label.SurfaceAirSamples")}}</h5>
        <a href="javascript:"
           :class="['SURFACEAIR', 'AIR'].includes(options.type) ? 'air-flat-button flatbutton': 'water-flat-button flatbutton'"
           @click="open('wBusiness')">
          <span class="glyphicons glyphicons-plus"></span>
        </a>
      </div>
      <div class="title">
        <h5 v-if="['SURFACEAIR', 'AIR'].includes(options.type)">{{$t("label.Packaging")}}</h5>
        <h5 v-else>{{$t("label.bottles")}}</h5>
        <a href="javascript:"
           class="water-flat-button flatbutton"
           v-bind:class="{ 'disabled': addButtonDisabled }"
           @click="open('bBusiness')">
          <span class="glyphicons glyphicons-plus"></span>
        </a>
      </div>
    </div>
    <div v-if="options.type === 'WATER' || options.type === 'AIR'" class="gauging-tube" :title="template.data.name + ' (' +  template.data.from + '-' + template.data.from + ')' ">
      <strong>{{template.data.name}}</strong> ({{template.data.from}}-{{template.data.to}} cm)
    </div>
    <div class="contentbox" v-bind:class="{ 'surface-water': (['SURFACEAIR','SURFACEWATER'].includes(options.type)) }" >
      <div v-for="(waterSample, key) in sortedWaterSample" :key="key" class="water-bottle" :class="'WB_'+waterSample.WsID" >
        <div v-if="['SURFACEAIR', 'AIR'].includes(options.type)" class="air">
          <asb :key="waterSample.WsGuid" :template='waterSample' :options='options' ></asb>
        </div>
        <div v-else class="water">
          <wsb :key="waterSample.WsGuid" :template='waterSample' :options='options' ></wsb>
        </div>

        <div v-if="$route.name === 'measurementPoint'" class="bottle">
          <bb v-for="bottle in waterSample.bottles" :key="bottle.BoGuid"
              :template='bottle'
              :bottleType="(['SURFACEAIR', 'AIR'].includes(options.type)) ? 'AIR' : 'WATER'"
              :options='{base:options,ws:waterSample}'></bb>
        </div>
        <div v-else class="bottle">
          <bb v-for="bottle in waterSample.bottles" :key="bottle.BoGuid"
              :template='bottle'
              :bottleType="(['SURFACEAIR', 'AIR'].includes(options.type)) ? 'AIR' : 'WATER'"
              :options='{base:options,ws:waterSample}' ></bb>
        </div>
      </div>
    </div>
  </div>
        <as-modal
          v-if="['AIR', 'SURFACEAIR'].includes(options.type)"
          :option="{
        name:template.data.name,
        suggestedName:suggestedNames.waBox,
        guid: template.data.guid,
        from:template.data.from,
        to:template.data.to,
        key:'wBusiness',
        type: options.type }"
          keep-alive
          :functions="{delete: remove, add: add, validate: validate, checkSubmit: checkSubmit}"></as-modal>
    <wa-modal
      v-if="!['AIR', 'SURFACEAIR'].includes(options.type)"
      :option="{
        name:template.data.name,
        suggestedName:suggestedNames.waBox,
        guid: template.data.guid,
        from:template.data.from,
        to:template.data.to,
        key:'wBusiness',
        type: options.type }"
      keep-alive
      :functions="{delete: remove, add: add, validate: validate, checkSubmit: checkSubmit}"></wa-modal>
    <pa-modal
      v-if="['AIR', 'SURFACEAIR'].includes(options.type)"
      :option="{
        name: template.data.name,
        suggestedName: suggestedNames.boBox,
        guid: template.data.guid,
        from:template.data.from,
        to:template.data.to,
        data: sortedWaterSample,
        key:'bBusiness'}"
              keep-alive
              :functions="{delete: remove, add: add, validate: validate, checkSubmit: checkSubmit}"></pa-modal>
  <bo-modal
    v-if="!['AIR', 'SURFACEAIR'].includes(options.type)"
    :option="{
        name: template.data.name,
        suggestedName: suggestedNames.boBox,
        guid: template.data.guid,
        from:template.data.from,
        to:template.data.to,
        key:'bBusiness'}"
            keep-alive
            :functions="{delete: remove, add: add, validate: validate, checkSubmit: checkSubmit}"></bo-modal>

  </div>
</template>
<script>
import wsb from '@/components/box-controls/waterSampleBox'
import asb from '@/components/box-controls/airSampleBox'
import bb from '@/components/box-controls/bottleBox'
import business from '@/business'
import waModal from '@/components/modal/waModal.vue'
import boModal from '@/components/modal/boModal.vue'
import paModal from '@/components/modal/paModal.vue'
import asModal from '@/components/modal/asModal.vue'

export default {
  name: 'waterBottle',
  components: {
    wsb,
    bb,
    asb,
    waModal,
    boModal,
    asModal,
    paModal
  },
  props: ['template', 'functions', 'options'],
  data () {
    return {
      classes: '',
      wBusiness: {},
      bBusiness: {},
      suggestedNames: {}
    }
  },
  computed: {
    addButtonDisabled: function () {
      let filterTube = this.options.data.find((obj) => obj.id === this.options.FtID)
      return filterTube && filterTube.waterSamples.length <= 0
    },
    sortedWaterSample: {
      get () {
        let sortedWaterSample = []
        if (['SURFACEWATER', 'SURFACEAIR'].includes(this.options.type)) {
          sortedWaterSample = this.template.data.waterSamples.filter((waterSample) => waterSample.FtID === undefined && waterSample.MpID !== undefined)
        } else {
          sortedWaterSample = this.template.data.waterSamples.filter((waterSample) => waterSample.FtID === this.options.FtID)
        }
        return sortedWaterSample
      }
    }
  },
  mounted () {
    this.wBusiness = business['watersample']
    this.bBusiness = business['bottle']
  },
  methods: {
    gotolist (key) {
      return this[key].gotolist(this)
    },
    open (key) {
      let type = ['AIR', 'SURFACEAIR'].includes(this.options.type) ? 'AIR' : 'WATER'
      return this[key].open(this, type)
    },
    remove (key) {
      let type = ['AIR', 'SURFACEAIR'].includes(this.options.type) ? 'AIR' : 'WATER'
      return this[key].delete(this, type)
    },
    add (key, data) {
      return this[key].add(this, data)
    },
    validate (key, data) {
      return this[key].validate && this[key].validate(this, data)
    },
    checkSubmit (evt, key, data) {
      return this[key].checkSubmit(evt, this, data)
    }
  }
}
</script>

<style lang="less">
  .water-bottle-box .contentbox {
    height: calc(100vh - 248px);
    overflow-y: auto
  }
  .water-bottle-box .contentbox.surface-water {
    height: calc(100vh - 210px);
  }
  .water-flat-button {
    background: #5E88D2;
  }
  .air-flat-button {
    background-color: #d25e88
  }
  .water-bottle-box {
    float: left;
    padding: 10px 10px 0;
    p {
      margin: 0
    }
    a {
      text-decoration: none;
      display: block
    }
    .title-box {
      overflow: hidden;
      .title {
        float: left;
        width: 180px;
        h5 {
          margin: 0 0 20px 0
        }
        a.flatbutton {
          transition: 0.5s;
          &:hover{
            transform: scale(0.90);
          }
        }
        +.title {
          margin-left: 20px;
          a.flatbutton {
            background: #83B5DE;
            transition: 0.5s;
            &:hover{
              transform: scale(0.90);
            }
          }
        }
      }
    }
    .gauging-tube {
      background: #07828a;
      color: #fff;
      text-align: center;
      padding: 7px;
      margin-bottom: 5px;
      overflow: hidden;
      width: 380px;
      white-space: nowrap;
    }
    .contentbox {
      .water-bottle {
        overflow: hidden;
        .item {
          overflow: hidden;
          width: 180px;
          height: 70px;
          color: black;
          display: flex;
          flex-direction: column;
          padding-left: 16px;
          position: relative;
          transition: 0.5s;
          &:hover,
          &:focus {
            transform: scale(0.95);
          }
          .text {
            z-index: 1
          }
          .bg {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            &.filter1 {
              background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.4, transparent), color-stop(.4, #666), color-stop(.5, #666), color-stop(.5, transparent), color-stop(.9, transparent), color-stop(.9, #666), to(#666));
              background-size: 10px 10px;
              opacity: .4;
            }
            &.disturb1 {
              background-color: #876E50;
            }
          }
          p{
            white-space: nowrap;
          }
        }
        .water {
          float: left;
          .item {
            background: linear-gradient(to right, #5e88d2 0 10px, #f3f4f6 0);
            border: 1px solid #d2d6db;
          }
        }
        .air {
          float: left;
          .item {
            background: linear-gradient(to right, #d25e88 0 10px, #f3f4f6 0);
            border: 1px solid #d2d6db;
          }
        }
        .bottle {
          float: left;
          margin-left: 20px;
          .item {
            background: linear-gradient(to right, #83B5DE 0 10px, #f3f4f6 0);
            border: 1px solid #d2d6db;
            height: 32px
          }
        }
        a {
          margin-bottom: 6px;
          &>.arrow {
            display: none;
          }
          &.active {
            position: relative;
            overflow: hidden;
            &>.arrow {
              display: block;
              width: 40px;
              height: 40px;
              position: absolute;
              background: #fff;
              z-index: 2;
              right: -20px;
              top: -20px;
              transform: rotate(45deg);
            }
            &:before {
              content: '';
              position: absolute;
              z-index: 3;
              top: 4px;
              right: 0;
              width: 13px;
              height: 8px;
              background: #515151;
              transform: rotate(-45deg);
            }
            &:after {
              content: '';
              position: absolute;
              z-index: 3;
              top: -3px;
              right: -1px;
              display: block;
              width: 15px;
              height: 10px;
              background: #fff;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }

  .inDetails {
      .water-bottle-box {
          background: #515151;

          .title-box {
              color: #fff
          }
      }
  }
</style>
