<template>
  <div>
    <app-header/>
    <div v-if="currentComplexWaterSample" class="waterSamplesDetail">
      <water-bottle
        :key="currentComplexWaterSample.WsID?currentComplexWaterSample.WsID:'Surface'"
        :template="{data:currentComplexWaterSample}"
        :options="{
          PrID: PrID,
          data: ComplexWaterSamples,
          MpGuid: MpGuid,
          FtGuid: currentComplexWaterSample.guid,
          FtID: currentComplexWaterSample.id,
          showAddButton: true,
          enableBoxLink: true,
          type: type
        }"
        class="inDetails">
      </water-bottle>
      <editors v-if="workingObject && workingObjectType === 'waterSample'" screen="frmWatersampleDetails" class="inDetails"></editors>
      <editors v-if="workingObject && workingObjectType === 'bottle'" screen="frmBottleDetails" class="inDetails"></editors>
      <nv-loading :show="loading"></nv-loading>

      <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup"/>
    </div>
    <app-footer :saveComponentDataFunction="save" />
  </div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import twsMapping from '@/configurations/app/tws-mapping'
import nvLoading from '@/components/_shared/loading'
import Editors from '@/components/editors.vue'
import waterBottle from '@/components/box-controls/waterBottle.vue'
import workingObjectUtils from '@/utils/workingObjectUtils'
import { WATER_SAMPLE_TYPE, BOTTLE_TYPE } from '@/vuex/constants'
import { MeasurementPointService } from '@/services/DataWS/measurementPointService'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

const measurementPointService = new MeasurementPointService()

var self

/**
   * The details of one specific watersample OR bottle.
   */
export default {
  name: '', // calculated to be route name
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    Editors,
    waterBottle,
    ExportProjectPopup
  },
  data () {
    return {
      PrID: '',
      MpGuid: '',
      FtGuid: '',
      WsID: '',
      WsGuid: '',
      BoGuid: '',
      BoID: '',
      type: '',
      RawObservations: [],
      ComplexWaterSamples: [],
      currentComplexWaterSample: null,
      loading: false,
      exportPopupOpen: false
    }
  },
  computed: {
    gaugingTubes: {
      get () {
        return this.$store.state.gaugingTubes
      },
      set (value) {
        this.$store.commit('setGaugingTubes', value)
      }
    },
    waterSamples: {
      get () {
        return this.$store.state.waterSamples
      },
      set (value) {
        this.$store.commit('setWaterSamples', value)
      }
    },
    surfaceWaterSamples: {
      get () {
        return this.$store.state.surfaceWaterSamples
      },
      set (value) {
        this.$store.commit('setSurfaceWaterSamples', value)
      }
    },
    bottles: {
      get () {
        return this.$store.state.bottles
      },
      set (value) {
        this.$store.commit('setBottles', value)
      }
    },
    observations: {
      get () {
        return this.$store.state.observations
      },
      set (value) {
        this.$store.commit('setObservatoins', value)
      }
    },
    measurementPoints: {
      get () {
        return this.$store.state.measurementPoints
      },
      set (value) {
        this.$store.commit('setMeasurementPoints', value)
      }
    },
    workingObject: {
      get () {
        return this.$store.state.workingObject
      },
      set (value) {
        this.$store.commit('setWorkingObject', value)
      }
    },
    workingObjectType: {
      get () {
        return this.$store.state.workingObjectType
      },
      set (value) {
        this.$store.commit('setWorkingObjectType', value)
      }
    },
    workingObjectIndex: {
      get () {
        return this.$store.state.workingObjectIndex
      },
      set (value) {
        this.$store.commit('setWorkingObjectIndex', value)
      }
    },
    currentMeasurementPoint: { // uses mpGuid and measurementpoints to get the current measurementpoint
      get () {
        if (this.measurementPoints) {
          if (this.MpGuid) {
            return this.measurementPoints.find(m => m.MpGuid === this.MpGuid)
          }
        }
        return null
      }
    },
    currentRouteName: {
      get () {
        return this.$route.name
      }
    }
  },
  watch: {
    '$route' (to, from) {
      this.prepareData()
    }
  },
  async mounted () {
    self = this
    // Set the local variables that are used troughout the component
    this.prepareData()
  },
  methods: {
    format () {
    }, // empty function, called by parent, do not remove

    formatComplexSurfaceWaterSamples () {
      let complexWaterSampleList = []
      let complexWaterSample = {}
      complexWaterSample.mpGuid = this.MpGuid
      let mp = this.measurementPoints.find(mp => mp.MpGuid === this.MpGuid)
      if (!mp) {
        console.error('No measurementpoint found with guid: [' + this.MpGuid + ']')
      }
      complexWaterSample.MpID = mp.MpID
      let subWaterSamples = this.surfaceWaterSamples
      if (subWaterSamples && subWaterSamples.length > 0) {
        for (let wSample of subWaterSamples) {
          // bind observation
          this.bindObservations(wSample, this.observations)
          // bind bottles
          this.bindBottle(wSample, this.bottles)
        }
      } else {
        subWaterSamples = []
      }
      complexWaterSample.waterSamples = subWaterSamples.reverse()
      complexWaterSampleList.push(complexWaterSample)

      this.ComplexWaterSamples = complexWaterSampleList
      this.RawObservations = this.observations
    },
    formatComplexWaterSamples () {
      let complexWaterSampleList = []
      if (this.gaugingTubes) {
        for (let gTube of this.gaugingTubes) {
          if (gTube.type === 'emptyBox') continue
          let complexWaterSample = {}
          complexWaterSample.id = gTube.FtID
          complexWaterSample.guid = gTube.FtGuid
          complexWaterSample.name = gTube.FtName
          complexWaterSample.from = gTube.FtFilterFrom
          complexWaterSample.to = gTube.FtFilterTo
          let subWaterSamples = this.waterSamples.filter(v => v.FtID === gTube.FtID)
          if (subWaterSamples && subWaterSamples.length > 0) {
            for (let wSample of subWaterSamples) {
              // bind observation
              this.bindObservations(wSample, this.observations)
              // bind bottles
              this.bindBottle(wSample, this.bottles)
            }
          } else {
            subWaterSamples = []
          }
          complexWaterSample.waterSamples = subWaterSamples.reverse()
          complexWaterSampleList.push(complexWaterSample)
        }
      }

      this.ComplexWaterSamples = complexWaterSampleList
      this.RawObservations = this.observations
    },
    bindBottle (wSample, bottles) {
      let subBottles = bottles.filter(v => v.WsID === wSample.WsID)
      if (subBottles) {
        let boIndex = 1
        for (let bottle of subBottles) {
          bottle.WsID = wSample.WsID
          bottle.BoID = boIndex.toString()
          bottle.BoBarcode = bottle.BoBarcode || ''
          bottle.BoFiltered = bottle.BoFiltered || ''
          boIndex++
        }
        wSample.bottles = subBottles
      } else {
        wSample.bottles = []
      }

      // wSample.WsID = newWsID
    },
    getKeyWithValue (transforms, raw) {
      for (var key in transforms) {
        if (transforms[key] === raw) {
          return key
        }
      }
    },
    bindObservations (wSample, observations) {
      let wsObservations = observations.filter(v => v.WsID === wSample.WsID)
      if (wsObservations && wsObservations.length) {
        wsObservations.forEach(v => (v.WsGuid = wSample.WsGuid))

        let observationMaps = twsMapping.observation

        for (let map of observationMaps) {
          if (!map) continue

          let observationItem = wsObservations.find(v => v.ObObservationType === map.type)
          if (observationItem) {
            let value = observationItem[map.field]
            if (map.transforms) {
              value = this.getKeyWithValue(map.transforms, value)
            }
            wSample[map.key] = value
          }
        }
      }
    },
    resetActiveTile () {
      this.waterSamples && this.waterSamples.forEach(ed => (ed.isActive = false))
      this.surfaceWaterSamples && this.surfaceWaterSamples.forEach(ed => (ed.isActive = false))
      this.bottles && this.bottles.forEach(ed => (ed.isActive = false))
    },
    setActiveWaterSample () {
      // this.waterSamples = this.currentComplexWaterSample.waterSamples
      this.workingObjectIndex = _.findIndex(this.currentComplexWaterSample.waterSamples, { WsID: this.$route.params.WsID })
      let bottles = []
      _.forEach(this.waterSamples, (waterSample) => {
        if (waterSample.bottles) {
          bottles = bottles.concat(waterSample.bottles)
        }
      })
      try {
        this.bottles = bottles.filter(v => v.type !== 'emptyBox')
      } catch (e) {
        console.error(e)
      }
      this.resetActiveTile()
      this.FtGuid = this.$route.params.FtGuid
      switch (this.type) {
        case 'SURFACEWATER':
          this.workingObject = this.surfaceWaterSamples.find((ws) => {
            return ws.WsID === this.WsID
          })
          break
        case 'WATER':
          this.workingObject = this.waterSamples.find((ws) => {
            return ws.WsID === this.WsID
          })
          break
        default:
          this.workingObject = {}
          console.error('The type is not know')
          break
      }
      this.workingObject.isActive = true
      this.workingObjectType = WATER_SAMPLE_TYPE
      return this.setState('WsName')
    },
    setActiveBottle () {
      this.waterSamples = this.currentComplexWaterSample.waterSamples
      let bottles = []
      _.forEach(this.waterSamples, (waterSample) => {
        bottles = bottles.concat(waterSample.bottles)
      })
      this.bottles = bottles.filter(v => v.type !== 'emptyBox')
      this.resetActiveTile()
      this.workingObjectIndex = _.findIndex(this.bottles, {
        WsID: this.$route.params.WsID,
        BoID: this.$route.params.BoID
      })
      if (this.workingObjectIndex !== -1) {
        this.workingObject = this.bottles[this.workingObjectIndex]
        this.workingObject.isActive = true
        this.workingObjectType = BOTTLE_TYPE
      } else {
        if (this.$route.params.BoID) {
          console.error('Bottle not found.')
        }
      }
      return this.setState('BoName')
    },
    ConstructWsOb () {
      let observationMaps = twsMapping.observation
      if (!observationMaps) {
        return
      }
      let watersamples = []
      let observations = []
      let mapkeys = observationMaps.map(v => v.key)
      let item = {}
      let keys = Object.keys(this.workingObject)
      let diff = _.difference(keys, mapkeys)
      diff.forEach(key => {
        if (!this.workingObject[key]) {
          this.workingObject[key] = ''
        }
        item[key] = this.workingObject[key]
      })
      if ((typeof item['FtID'] !== 'undefined') && (item['FtID'] !== null)) {
        item['FtID'] = '1' // bad old hack for local id's
      }
      watersamples.push(item)
      observationMaps.forEach(v => {
        if (!this.workingObject[v.key]) {
          this.workingObject[v.key] = '' // make empty string to send to the server
        }
        let ob
        if (observations.length) {
          ob = observations.find(o => o.ObObservationType === v.type && o.WsID === this.workingObject.WsID)
        }
        if (ob) {
          ob[v.field] = this.workingObject[v.key]
          return
        }
        ob = {}
        if (v.unit !== '-1') {
          ob.ObMeasurementUnit = v.unit
        }// default unit
        ob.ObObservationType = v.type
        let value = this.workingObject[v.key]
        if (v.transforms) {
          value = v.transforms[value]
        }
        ob[v.field] = value
        let rawOb = this.RawObservations.find(i => i.WsGuid === this.workingObject.WsGuid && i.ObObservationType === v.type)
        rawOb ? ob.ObID = rawOb.ObID : ob.ObID = undefined
        ob.PrID = this.PrID
        ob.WsID = this.workingObject.WsID
        ob.WsGuid = this.workingObject.WsGuid
        observations.push(ob)
      })
      return {
        WaterSamples: watersamples,
        Observations: observations
      }
    },
    setState (key) {
      return this.$store.dispatch('setActiveTitle', this.workingObject[key])
        .then(() => { return this.$store.dispatch('setPrevTitle', (this.currentMeasurementPoint || {}).MpName) })
    },
    init (screenname) {
      this.screen = screenname
      if (this.$options.name !== this.$route.name) {
        this.$options.name = this.$route.name
      }
    },
    load () {
      return new Promise((resolve, reject) => {
        let waterSamples = this.currentComplexWaterSample.waterSamples
        switch (this.$route.name) {
          case 'watersample':
          case 'watersampleSurface':
            this.init('frmWatersampleDetails')
            if (_.isArray(waterSamples) && waterSamples.length) {
              return this.setActiveWaterSample().then(() => {
                resolve(true)
              })
            } else {
              resolve(false)
            }
            break
          case 'bottle':
          case 'bottleSurface':
            this.init('frmBottleDetails')
            if (_.isArray(waterSamples) && waterSamples.length) {
              return this.setActiveBottle().then(function () {
                resolve(true)
              })
            } else {
              resolve(false)
            }
            break
          default:
            console.error('WaterSampleDetail loaded with unknown route name [' + this.$route.name + '].')
            resolve(false)
        }
      })
    },
    setLocalVariables () {
      this.PrID = this.$route.params.projectId
      this.MpGuid = this.$route.params.measurementPointId
      this.FtGuid = this.$route.params.FtGuid
      this.WsID = this.$route.params.WsID
      this.BoID = this.$route.params.BoID
      switch (this.currentRouteName) {
        case 'watersampleSurface':
          this.type = 'SURFACEWATER'
          break
        case 'watersample':
          this.type = 'WATER'
          break
        case 'bottle':
          this.type = 'WATER'
          break
        case 'bottleSurface':
          this.type = 'SURFACEWATER'
          break
        default:
          this.type = 'Undefined'
          console.error('The route name should always exists')
      }
    },
    async prepareData () {
      await new Promise((resolve, reject) => {
        this.setLocalVariables()

        let dataTasks = []
        if (!_.isArray(this.measurementPoints) || !this.measurementPoints.length) {
          dataTasks.push(this.getMeasurementpoints())
        }
        // Only retrieve the gaugingtubes if it is a water sample
        if (this.type !== 'SURFACEWATER') {
          dataTasks.push(this.getGaugingtubes())
        }
        dataTasks.push(this.$store.dispatch('fetchWaterSurfaceWaterAirSurfaceAirSamplesBottlesAndObservations', { projectId: this.PrID, MpGuid: this.MpGuid }))
        return Promise.all(dataTasks).then(this.setSelectedWaterSample).then(() => {
          return this.load()
        }).then(() => {
          this.loading = false
          return resolve(true)
        }, (err) => { console.error(err) })
      })
    },
    getMeasurementpoints () {
      return measurementPointService.getMeasurementPoints(this.PrID).then((measurementPointResponse) => {
        if (measurementPointResponse) {
          this.measurementPoints = measurementPointResponse.tblMeasurementPoints
        }
      })
    },
    getGaugingtubes () {
      return this.$store.dispatch('fetchGaugintubes', { projectId: this.PrID, MpGuid: this.MpGuid })
    },
    setSelectedWaterSample () {
      return new Promise((resolve, reject) => {
        if (this.type === 'WATER') {
          this.formatComplexWaterSamples()
          this.currentComplexWaterSample = this.ComplexWaterSamples.find(v => v.guid === this.FtGuid)
        } else if (this.type === 'SURFACEWATER') {
          this.formatComplexSurfaceWaterSamples()
          this.currentComplexWaterSample = this.ComplexWaterSamples.find(v => v.mpGuid === this.MpGuid)
        } else {
          console.error('Water sample type: [' + this.type + '] does not exist.')
        }

        this.WsGuid = null
        let currentWaterSample = this.currentComplexWaterSample.waterSamples.find(w => w.WsID === this.WsID)
        if (currentWaterSample && currentWaterSample.WsGuid) {
          this.WsGuid = currentWaterSample.WsGuid
        }
        // calculate BoGuid
        if (this.BoID) {
          let currentBottle = currentWaterSample.bottles.find(w => w.BoID === this.BoID)
          if (currentBottle && currentBottle.BoGuid) {
            this.BoGuid = currentBottle.BoGuid
          }
        }
        resolve(true)
      })
    },
    save () {
      this.loading = true
      let saveData = {
        PrID: this.$route.params.projectId,
        MpGuid: this.$route.params.measurementPointId,
        FtGuid: this.$route.params.FtGuid
      }

      if (this.$store.state.workingObjectType === WATER_SAMPLE_TYPE) {
        let wsob = this.ConstructWsOb() // split combined watersample object back into observations and watersamples
        saveData['WaterSamples'] = wsob.WaterSamples
        saveData['Observations'] = wsob.Observations
      } else if (this.$store.state.workingObjectType === BOTTLE_TYPE) {
        let bottle = _.cloneDeep(this.workingObject)
        let waterSample = this.$store.state.waterSamples.find(ws => ws.WsID === bottle.WsID)
        saveData['WsGuid'] = waterSample.WsGuid
        bottle.WsID = '1'
        saveData['Bottles'] = [bottle] // working object put into an array is all we need to send
      }

      return workingObjectUtils.pushWorkingObjectToDataService(saveData)
        .then(this.prepareData).then(() => {
          return true
        })
    },
    delete () {
      let modalDelete
      switch (self.$route.name) {
        case 'watersample':
        case 'watersampleSurface':
          modalDelete = $('#modalDeleteWaterSample' + self.$route.params.FtGuid)
          break
        case 'bottle':
        case 'bottleSurface':
          modalDelete = $('#modalDeleteBottle' + self.$route.params.FtGuid)
          break
        default:
          return
      }
      modalDelete.modal('toggle')
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  }
}

</script>
